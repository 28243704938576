

// The very last import in the main.scss. This can be used to override any scss no matter where it is included from.


table, th, td {
    border: 1px solid;
    line-height: 2.2;
    text-align: center;
    border-color: rgb(209,209,209);
    box-sizing: border-box;
    padding: 15px;
}

tr:nth-of-type(2n) {
    background-color: #eee;
}

thead {
    background-color: rgb(243, 130, 48);
    color: rgb(255,255,255);
    font-weight: 700;
    font-size: 18px;
}

.mermaid {
    .actor {
        stroke: hsl(0, 0%, 90%) !important;
        fill: hsl(0, 0%, 90%) !important;
    }
}

.mermaid {
    text.actor>tspan {
        stroke: none !important;
        fill: #000 !important;
    }
}

g {
    line {
        stroke: hsl(0, 0%, 90%) !important;
        fill: hsl(0, 0%, 90%) !important;
    }
}


