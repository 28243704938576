a .highlighter-rouge {
  color: #f38230;
}

.highlighter-rouge {
    /* top right bottom left*/
  padding:  2px 3px 2px 3px;
  background: #f3f3f3;
  margin: 0 0 2em;
  font-size: 12px;
  line-height: 1.7;
  color: $black;

  &.bg-info {
    padding:  0 0 0 3%;
    border-left: 4px solid #d2d2d2;
    border-color: #f38230;
  }

  &.bg-example {
    padding:  0 0 0 3%;
    border-left: 4px solid #d2d2d2;
  }

  pre {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.highlight {
  padding: 4px 0 4px;
}
