// custom variables

$body-bg: #fff;

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #555555 !default;
$gray-800: #424242 !default;
$gray-900: #212529 !default;
$black:    #000      !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #f58220 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


$body-color:                $black !default;
$code-font-size:            11px !default;

$theme-colors: (
	primary: $orange,
	secondary: $gray-600,
	success: $green,
	info: rgba(243, 130, 48, 0.15),
	warning: $orange,
	danger: $red,
	light: $gray-100,
	dark: #555
);

$spacer:   1rem !default;
$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 4.5),
	7: ($spacer * 6),
	8: ($spacer * 7.5),
	9: ($spacer * 9),
	10: ($spacer * 10.5)
);

$base-min-width: 320px;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// $link-color:                theme-color("primary") !default;
$link-decoration:           none;
// $link-hover-color:          darken($link-color, 15%) !default;
$link-hover-decoration:     none;

$container-max-widths: (
  sm: 1054px
);

$font-family-sans-serif:      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$line-height-base:            1.5;
