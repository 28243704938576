html {
    font-size: 16px;
    overflow-y: scroll;
}

body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	min-width: $base-min-width;

	&.nav-active {
		@include media ('<desktop') {
			height: 100vh;
			overflow: hidden;
		}
	}
}

.container {
	max-width: 1280px;
}

img{
	max-width: 100%;
	height: auto;
}

.resize-active{
	*{
		transition: none !important;
	}
}

#wrapper{
	overflow: hidden;
	position: relative;
}

.page-header{
	padding: 12px 0 11px;
}

.page-title{
	font-size: 24px;
	line-height: 1.1;
	text-transform: uppercase;
}

// .last-nav-list {
// 	word-break: break-all;
// }
