.blockquote ul {
    margin-top: 1em;
    margin-bottom: 0em;
}

.blockquote {
    font-size: 16px;
    line-height: 1.4;
    color: #424242;
    background-color: #f4f4f4;
    padding: 1em;
    margin: 0 0 1em;
    highlight {
        background-color: rgba(243, 130, 48, 0.15);
    }
    box-sizing: border-box;
}

.blockquote-highlight {
    @extend .blockquote;
    background-color: rgba(243, 130, 48, 0.15);
}

.nested-nav-wrap {
  padding: 30px 0 0;
  margin: 0 0 10px;
  @include media ('>=tablet') {
    padding: 35px 0 0;
    margin: 0 0 29px
  }
}

.nested-nav-list {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  @include media ('<tablet') {
    flex-wrap: wrap;
    font-size: 14px;
  }

  li {
    @include media ('<phone') {
      width: 100%;
    }

    &:not(:last-child) {
      margin: 0 0 1em;
      @include media ('>=phone') {
        margin: 0 1em 0 0;
      }
      @include media ('>=tablet') {
        margin: 0 1.8em 0 0;
      }
      @include media ('>=desktop') {
        margin: 0 4.8em 0 0;
      }
    }

    &.active {
      a {
        color: $black;

        &:before {
          opacity: 1;
        }
      }
    }

    a {
      color: theme-color("warning");
      position: relative;
      transition: color .27s linear;

      &:hover,
      &:focus {
        color: $black;

        &:before {
          height: 2px;
          opacity: 1;
          @include media ('>=tablet') {
            height: 3px;
          }
        }
      }

      &:before {
        content: '';
        right: -2px;
        left: -2px;
        opacity: 0;
        height: 2px;
        background: $black;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: height .27s linear, opacity .27s linear;
        @include media ('>=tablet') {
          bottom: auto;
          top: -0.7em;
          height: 3px;
        }
      }
    }
  }
}

.doc-wrap {
  @extend %clearfix;

  @include media ('>=tablet') {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

/* documentation navigation */
.doc-nav {
  @include media('<tablet') {
    height: auto !important;
  }

  @include media('≥tablet') {
    width: 30%;
    overflow: auto;
  }

  @include media ('>=tablet') {
    background: $white;
    max-width: 100%;
    top: 0 !important;
  }

  &.fixed {
    @include media ('>=tablet') {
      position: fixed;
      height: 98vh;
      top: 0;
      left: 15px;
    }
    @include media ('>=1054px') {
      left: calc((100vw - 1039px) / 2);
    }
  }

  .fixed-position & {
    @include media ('>=tablet') {
      max-height: 100vh !important;
      overflow: auto;
    }
  }
}

.doc-nav-list {
  font-size: 14px;
  line-height: 1;
  color: #424242;
  min-width: 100%;
  display: table;

  > li {
    > .nav-header,
    > a,
    > button {
      width: 100%;
      display: block;
      text-align: left;
      padding: 18px 19px 15px;
      border-bottom: 1px solid #d9d9d9;
      letter-spacing: .05em;
    }

    > .nav-header {
        padding: 10px 19px 15px;
        font-weight: bold;
    }

    > button {
      position: relative;

      &:before {
        font-family: 'icomoon' !important;
        content: '\e901';
        font-size: 1.2em;
        position: absolute;
        color: #95989a;
        right: 33px;
        top: 50%;
        transform: translate(0, -50%) rotate(0deg);
        transition: transform .27s linear, border-color .27s linear;
      }

      &[aria-expanded="true"] {
        border-bottom: transparent;

        &:before {
          transform: translate(0, -50%) rotate(-180deg);
        }
      }
    }
  }

  a {
    color: #424242;
    outline: none;
    transition: color .27s ease-in-out;

    &:hover {
      color: theme-color("warning");
    }

    &.active {
      color: theme-color("warning");
    }
  }

  button {
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
    cursor: pointer;
    color: #424242;
    transition: color .27s ease-in-out;

    &:hover {
      color: theme-color("warning");
    }
  }

}

.sub-nav-list {
  background-color: #f4f4f4;

  > li {
    > a,
    > button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: 0 0 0 33px;
      position: relative;
      position: relative;
      letter-spacing: .055em;
      padding: 9px 5px 9px 23px;
    }

    > button {
    	position: relative;
      padding: 9px 5px 9px 23px;

      &:before {
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    font-family: 'icomoon' !important;
		    content: '\f0da';
		    display: inline-block;
		    font-size: .9em;
		    transform: rotate(0deg);
		    transition: transform .27s linear;
		    color: #424242;
		    margin: 0 .4em 0 0;
		    margin: auto;
		    height: 11px;
		    left: 13px;
      }

      &[aria-expanded="true"] {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.last-nav-list {
  padding: 0 0 0 40px;

  a {
    display: block;
    padding: 7px 5px 7px 0;
    //word-wrap: break-word;
  }
}

/* documentation main content */
.doc-body {
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  //padding-bottom: 20px;

  @include media ('>=tablet') {
    width: 76%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 15px 0 0 15px;
  }

  @include media ('>=desktop') {
    font-size: 16px;
  }

  @include media ('>=widescreen') {

    padding-top: 0;
    padding-left: 3px;
  }

  &.wide-mod {
    @include media ('>=tablet') {
      max-width: 70%;
      padding: 15px 0 0 15px;
    }

    .content-holder {
      @include media ('>=tablet') {
        max-width: 100%;
      }
    }
  }

  .content-holder {
    padding-bottom: 2em;
  }

  h1 {
    font-size: 32px;
    line-height: 1;
    color: $black;
    font-weight: 300;
    margin: 1.1em 0 0.7em;
    @include media ('>=desktop') {
      font-size: 36px;
      margin: 1.1em 0 0.7em;
    }
    word-wrap: break-word;
  }

  h2 {
    font-size: 17px;
    font-weight: 700;
    margin: 1.7em 0 1.0em;
    text-transform: uppercase;
  }

  h3 {
    font-size: 17px;
    font-weight: 700;
    margin: 1.7em 0 1.0em;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    margin: 0 0 1.0em;
  }

  hr {
    border-color: #707070;
    border-width: 2px;
    margin: 1.5em 0;
    @include media ('>=desktop') {
      margin: 2.3em 0;
    }
  }

  ul {
    padding: 0 0 0 1.2em;
  }

  dl {
    margin: 0 0 2em;
    font-size: 14px;

    > div {
      margin: 0 0 .3em;
    }

    dt {
      display: inline;
      vertical-align: middle;
    }

    dd {
      display: inline;
      vertical-align: middle;
    }

    code {
      background-color: #f4f4f4;
      color: $black;
      font-size: 12px;
      line-height: 24px;
      font-weight: 300;
      padding: .2em .6em;
      margin: 0 .4em 0 0;
    }
  }

  .font-small {
    font-size: 14px;
  }
}

/* documentation anchor list */
.doc-anchor {
  margin: 0 0 2em;

  @include media ('>=widescreen') {
    width: 163px;
    margin: 0;
  }

  &.fixed {
    @include media ('>=widescreen') {
      height: 98vh;
      position: fixed;
      top: 0;
      right: calc((100vw - 1159px) / 2);
    }
  }

  .anchor-list {
    border-left: 3px solid theme-color("warning");
    font-size: 14px;
    line-height: 1.2;
    padding: 2px 1px 0 15px;
    margin: 0;

    > li {
      &:not(:last-child) {
        margin: 0 0 .85em;
      }

      a {
        color: #424242;
        font-weight: 300;
        transition: color .27s ease-in-out;

        &:hover {
          color: theme-color("warning");
        }

        &.anchor-active {
          font-weight: 400;
        }
      }
    }
  }
}


/* overview styles*/

.intro-header {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33;
  margin: 0 0 2em;
  @include media ('>=desktop') {
    font-size: 18px;
    margin: 0 0 41px;
  }

  h1 {
    font-size: 32px;
    line-height: 1.2;
    margin: 0 0 .5em;
    @include media ('>=desktop') {
      font-size: 36px;
      margin: 0 0 .6em;
    }
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 1em;
    }
  }
}

.intro-list {
  margin: 0 -15px;

  &.two-column {
    > li {
      width: 50%;
      padding: 0 15px;
      margin: 0 0 1.5em;
      @include media ('>=phone') {
        margin: 0 0 2em;
      }
    }
  }

  > li {
    width: 50%;
    padding: 0 15px;
    margin: 0 0 1.5em;
    @include media ('>=phone') {
      margin: 0 0 2em;
    }
    @include media ('>=tablet') {
      width: 33.3333%;
    }
  }
}

.jumbo-card {
    flex-shrink: 0;
    margin: 0 0 10px;
    @include media ('>=phone') {
        margin: 0 8px 0 0;
    }
    @include media ('>=desktop') {
        width: 400px;
        margin: 0.8em 0 0 0;
    }
    h3 { // top right bottom left
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 .3em;
        @include media ('>=desktop') {
            font-size: 18px;
            margin: 1.5em 0 1.5em 0;
        }
        a {
            color: theme-color("warning");
            &:hover {
                color: $black;
            }
            &.active {
                color: $black;
            }
            outline: none;
            transition: color .27s ease-in-out;
        }
    }
}

.jumbo-card-icon {
    @include media ('>=desktop') {
      margin: 0;
      padding: 2px;
      border: 1px solid transparent;
      transition: border .27s ease-in-out;
    }
    &:hover {
        border: 1px solid #ccc;
    }
}

.screenshot {
    text-align: center;
    align: center;
    filter: drop-shadow(0.15rem 0.15rem 0.15rem #ccc);
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.mobile-screenshot {
    @extend .screenshot;
    width: 40%;
}

.intro-card {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 300;
  color: #424242;
  transition: color .27s ease-in-out;

  @include media ('<phone') {
    flex-wrap: wrap;
  }
  @include media ('>=desktop') {
    font-size: 18px;
  }

  h3 {
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 0.2em;
    @include media ('>=desktop') {
      font-size: 18px;
    }
  }

  .entry-icon {
    width: 40px;
    flex-shrink: 0;
    margin: 0 0 10px;
    @include media ('>=phone') {
      margin: 0 8px 0 0;
    }
    @include media ('>=desktop') {
      width: 108px;
      margin: 0;
    }
    img {
        padding: 2px;
        border: 1px solid transparent;
        transition: border .27s ease-in-out;

        &:hover {
            border: 1px solid #ccc;
        }
    }
  }

  .entry-body {
    @include media ('<phone') {
      width: 100%;
    }

    p {
      margin: 0;
      font-size: 16px;

      &:not(:last-child) {
        margin: 0 0 .3em;
      }
    }
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: theme-color("warning");
    }
  }
}

a.intro-card {
  &:hover,
  &:focus {
    color: theme-color("warning");
  }
}

.intro-image {
    &:hover {
        border: 3px solid #ccc;
        color: theme-color("warning");
    }
}

.features-section {
  padding: 30px 0;
  position: relative;

  @include media ('>=tablet') {
    padding: 40px 0;
  }

  @include media ('>=desktop') {
    padding: 48px 87px 88px;
  }

  &:before {
    content: '';
    width: 100vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: inherit;
    z-index: -1;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: #424242;
    margin: 0 0 1.2em;
    @include media ('>=desktop') {
      font-size: 18px;
      margin: 0 0 2.5em;
    }
  }
}

.features-heading {
  font-size: 32px;
  line-height: 1.1;
  font-weight: 300;
  margin: 0 0 .8em;

  @include media ('>=desktop') {
    font-size: 36px;
    margin: 0 0 1.02em;
  }
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  @include media ('>=phone') {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  > .entry-item {
    width: 100%;
    @include media ('>=phone') {
      width: 49%;
    }
    @include media ('>=tablet') {
      width: 40%;
    }
    @include media ('>=desktop') {
      width: 34.4%;
    }

    &:not(:last-child) {
      @include media ('<phone') {
        margin: 0 0 3em;
      }
    }
  }
}

.features-list {
  padding: 0 0 0 15px;

  > li {
    &:not(:last-child) {
      margin: 0 0 1.5em;
      @include media ('>=desktop') {
        margin: 0 0 2.66em;
      }
    }
  }
}

.features-card {
  .entry-icon {
    flex-shrink: 0;
    width: 40px;
    margin: 0 10px 0 0;
    @include media ('>=desktop') {
      margin: 0 22px 0 0;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 1.333;
    font-weight: 700;
    color: #424242;
    margin: 0;
    @include media ('>=tablet') {
      font-size: 16px;
    }
    @include media ('>=desktop') {
      font-size: 18px;
    }
  }
}
// ---------------------------------------------

.sticky-wrap-doc-nav {
  width: 100%;
  float: left;

  .doc-nav {
  	width: 100%;
	}

  @include media('≥tablet') {
  	width: 24%;
  }
}

.sticky-wrap-doc-anchor {
  width: 160px;
}

.content-holder {
	padding: 0 15px;

	@include media('≥tablet') {
            max-width: calc(100% - 180px);
            min-width: 600px;
    margin-right: 20px;
  }

  // @include media('≥desktop') {
	// 	max-width: 570px;
	// }
}
