#header {
  .container {
    justify-content: space-between;
  }
}

    .header-divider {
        border-left: 1px solid #676767;
        height: 30px;
        margin: 0 15.5px;
        position: relative;
        vertical-align: middle;
    }


.logo {
  margin: 0 0 0 7px;

  @include media ('<tablet') {
      max-width: 104px;
  }

  max-width: 110px;
  min-width: 110px;
}

.nav-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  max-width: 1000px;
  flex: 0 0 1000px;

  @include media ('<desktop') {
    max-width: 100%;
    flex: 0 0 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: theme-color("dark");
    z-index: 99998;
    width: 100%;
    padding: 66px 0 0;
    overflow: hidden;
    overflow-y: auto;
    transform: translate(100%, 0);
    opacity: 0;
    visibility: hidden;

    transition: {
      property: transform, opacity, visibility;
      duration: .3s;
      timing-function: linear;
    }
  }

  .nav-active & {
    @include media ('<desktop') {
      transform: translate(0, 0);
      opacity: 1;
      visibility: visible;
    }
  }

  .nav-button {
      	font-family: "Helvetica Neue",Helvetica, Arial, sans-serif;
        text-rendering: optimizeLegibility;
        border: 1px solid transparent;
        border-radius: 0;
        box-sizing: border-box;
        color: $white;
        cursor: pointer;
        display: inline-block;
        font-size: 9.8px;
        font-weight: bolder;
        letter-spacing: .03em;
        line-height: 23.5px;
        margin-top: 0;
        max-width: 100%;
        padding: 0 1.2em;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-shadow: none;
        text-transform: uppercase;
        transition: transform .3s,border .3s,background .3s,box-shadow .3s,opacity .3s,color .3s;
        vertical-align: middle;
        border-radius: 99px;
  }

  .nav-button-1 {
      @extend .nav-button;
      background-color: $orange;
  }

  .nav-button-2 {
      @extend .nav-button;
      background-color: transparent;
      border: 2px solid;
  }

}

#nav {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  flex-wrap: wrap;

  @include media ('>=desktop') {
    flex-wrap: nowrap;
  }

  li {
    @include media ('<desktop') {
      width: 100%;
    }

    &.active {
      a {
        background-color: #989898;
        color: $white;
      }
    }
  }

  a {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    transition: background-color .27s ease-in-out, color .27s ease-in-out;
    text-transform: uppercase;
    padding: 19px 10px;
    letter-spacing: .02em;

    &:hover,
    &:focus {
      background-color: #989898;
      color: $white;
    }
  }
}

.site-search {
  max-width: 167px;
  display: flex;
  position: relative;

  @include media ('<desktop') {
    max-width: 50%;
  }

  @include media ('>=1054px') {
    right: 0;
  }

  &.show {
    a {
      i {
        &:before {
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
        }

        &:after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }
  }

  a {
    color: $white;
    font-size: 25px;
    width: 66px;
    text-align: center;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #424242;
    transition: color .27s ease-in-out, background-color .27s ease-in-out;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 9999;

    &:after {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: theme-color("dark");
    }

    i {
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform .3s linear, opacity .3s linear;
      }

      &:before {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }

      &:after {
        font-family: 'icomoon' !important;
        content: "\e5cd";
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
      }
    }
  }

  .dropdown-menu {
    display: flex !important;
    justify-content: flex-end;
    transform: translate(0, 66px) !important;
    top: 0 !important;
    left: auto !important;
    right: 0;
    padding: 0;
    margin: 0;
    border: none;
    background: theme-color("dark");
    height: 50px;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    transition: transform .3s linear, visibility .3s linear, opacity .3s linear;

    @include media ('<widescreen') {
      right: calc((100vw - 1024px) / 2 * -1);
    }

    @include media ('<desktop') {
      right: 0;
    }

    &.show {
      transform: translate(0, 66px) !important;
      visibility: visible;
      opacity: 1;
    }
  }
}

.search-form {
  display: flex;
  width: 100%;

  input[type="search"] {
    border: none;
    outline: none;
    background: rgba(255, 255, 255, 0.2);
    padding: 0 35px 0 10px;
    flex-grow: 1;
    height: 30px;
    width: 100%;
    border-radius: 99px;
    color: #fff;
    font-size: 13px;
    font-weight: 300;

    @include media ('<desktop') {
      padding-left: 10px;
    }

    @include placeholder {
      color: #fff;
      text-transform: capitalize;
    }
  }

  button {
    width: 22px;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    border: none;
    background-color: transparent;
    padding: 0 1.5em 0;
    color: #a5a5a5;
    cursor: pointer;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 0;
    font-size: 14px;
    color: #fff;
    padding: 0;
    overflow: hidden;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $white;
      background: transparent;
    }
  }
}

.burger-wrap {
  position: relative;
  z-index: 99999;
  margin: 0 0 0 auto;

  @include media ('>=desktop') {
    display: none;
  }
}

.nav-opener {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;

  .nav-active & {
    span {
      opacity: 0;
    }

    &:before,
    &:after {
      transform: rotate(45deg);
      top: 50%;
      left: 15%;
      right: 15%;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  span,
  &:before,
  &:after {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    height: 3px;
    margin-top: -2px; /* height/2 */
    transition: all .3s linear;
  }

  &:before,
  &:after {
    content: '';
    top: 30%;
  }

  &:after {
    top: 70%;
  }
}
